import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Logo from "../assets/images/secondLogo.png";
import { AXIOS_LOGIN, AXIOS_POST } from "../config/axios";
import { forgetpassword, signup } from "../features/ShowAccountSlice";
import LanguageSwitch from "./languageSwitch";
import { LOGIN_URL } from "../helper/Url";
import Loader from "../helper/Loader";
import { handleLogin } from "../helper/Auth";
import { useNavigate } from "react-router-dom";
import Translate from "../config/Translate";
import ErrorBox from "../helper/Messages/Error";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import { selectLanguage } from "../features/LanguageSlice";
import En from "../helper/Dictionary/En";
import Fr from "../helper/Dictionary/Fr";
import { companyInfo } from "../helper/Company";
const SignIn = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  let hostname = window.location.hostname.split(".").slice(-3).join(".");
  let hostnameIndex = hostname.split(".")[0];
  const [Errors, setErrors] = useState(null);
  const languageValue = useSelector(selectLanguage);
  const cInfo = companyInfo();
  let count = 5;
  const pEmail =
    languageValue === "En"
      ? En["page.signUpEmailPlaceholder"]
      : Fr["page.signUpEmailPlaceholder"];
  const pPassword =
    languageValue === "En"
      ? En["page.loginPswPlaceholder"]
      : Fr["page.loginPswPlaceholder"];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSignIn = (data) => {
    console.log(data);
    count  = count - 1;
    setShowLoader(true);
    AXIOS_LOGIN(LOGIN_URL, data, null, languageValue == "En" ? "en" : "fr")
      .then((result) => {
        setShowLoader(false);
        handleLogin(result.data);
        let user_first_data = JSON.parse(localStorage.getItem("user"));
        user_first_data?.user_verified
          ? navigate("/dashboard/profile")
          : navigate("/confirm_email");
      })
      .catch((e) => {
        setShowLoader(false);
        if (e.response?.data?.message) {
          setErrors(e.response.data.message);
        } else {
          count > 0 && handleSignIn(data);
          if (count < 1){
            languageValue === "En"
              ? setErrors("Connection error, try again!")
              : setErrors("Erreur de connexion, réessayez!");
          }
        }
      });
  };
  return (
    <Container>
      <PageIntro>
        {/* <h1 className="title">{cInfo.cname}</h1>  */}
        <ImgContainer src={cInfo.CimagePath} alt="logo" />
        <Title>
          {hostnameIndex == "aigle" && (
            <Translate Word="page.loginWelcomeEvcaigle" />
          )}
          {hostnameIndex == "etoy" && (
            <Translate Word="page.loginWelcomeVetmidiEtoy" />
          )}
          {hostnameIndex == "evc" && (
            <Translate Word="page.loginWelcomeEvcetoy" />
          )}
          {hostnameIndex == "crissier" && (
            <Translate Word="page.loginWelcomeCrissier" />
          )}
          {hostnameIndex == "geneve" && (
            <Translate Word="page.loginWelcomeGeneve" />
          )}
          {hostnameIndex == "pully" && (
            <Translate Word="page.loginWelcomePullys" />
          )}
        </Title>
        <p>
          <Translate Word="page.loginSubtext" />
        </p>
      </PageIntro>
      <Form onSubmit={handleSubmit(handleSignIn)}>
        <Input
          type="email"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          placeholder={pEmail}
        />
        {errors?.email?.type == "required" ? (
          <Perror>
            <Fade bottom>
              <Translate Word="errors.form.email.required"></Translate>
            </Fade>
          </Perror>
        ) : (
          ""
        )}
        {errors?.email?.type === "pattern" ? (
          <Perror>
            <Fade bottom>
              <Translate Word="errors.form.email.required.valid"></Translate>
            </Fade>
          </Perror>
        ) : (
          ""
        )}
        <Input
          type="password"
          {...register("password", {
            required: true,
          })}
          placeholder={pPassword}
        />
        {errors?.password?.type === "required" ? (
          <Perror>
            <Fade bottom>
              <Translate Word="errors.form.password.required"></Translate>
            </Fade>{" "}
          </Perror>
        ) : (
          ""
        )}
        <FormAction>
          <div>
            <ActionLabel htmlFor="remember">
              <span>
                <Input type="checkbox" id="remember" />
              </span>
              <span>
                <Translate Word="page.signInRememberMe" />
              </span>
            </ActionLabel>
          </div>
          <Button>
            {" "}
            {showLoader ? (
              <Loader />
            ) : (
              <Translate Word="page.LoginNow"></Translate>
            )}
          </Button>
        </FormAction>
        {Errors && <ErrorBox Message={Errors}></ErrorBox>}
      </Form>
      <ForgetContainer>
        <ForgetLink
          onClick={() => {
            dispatch(forgetpassword());
          }}
        >
          <Translate Word="page.signInForgotPass" />
        </ForgetLink>
      </ForgetContainer>
      <LanguageSwitch />
      <Copyright>© {new Date().getFullYear()} Digimidi</Copyright>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.85);
`;
const ImgContainer = styled.img`
  width: 150px;
  object-fit: cover;
`;
const PageIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;
const Title = styled.h3`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 5px;
  font-size: 13px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 9px;
  outline: none;
  margin-top: 14px;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const Form = styled.form``;
const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Button = styled.button`
  padding: 2px 16px;
  height: 30px;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
`;
const ForgetContainer = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin: 30px 0;
`;
const Copyright = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-top: 35px;
  text-align: center;
`;
const Label = styled.label`
  display: flex;
  align-items: start;
`;

const ForgetLink = styled.p`
  color: rgb(121, 121, 121);
  margin-bottom: 15px;
  font-size: 13px;
  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
`;
const SignUpLink = styled.p``;
const SingUpAction = styled.span`
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
`;
const Perror = styled.p`
  color: #d03333e0;
  font-size: 14px;
`;
const ActionLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;
