import React, { useState, useEffect } from 'react';
import ClinicChangePopUp from './ClinicChangePopUp';
import styled from "styled-components";
import Uploader from "../Uploader";
import Translate from "../../../config/Translate";
import { useSelector } from "react-redux";
import { selectUserInfo, setUserConfigId } from "../../../features/getUserSlice";
import { useDispatch } from "react-redux";
import { setSelectedClinic } from '../../../features/getUserSlice';
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AXIOS_GET, AXIOS_POST } from "../../../config/axios";
import { GET_CONFIG_URL, GET_CONFIGS_URL, CHANGE_CLINIC } from "../../../helper/Url";
import { setUserInfo } from "../../../features/getUserSlice";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { FaArrowDown } from 'react-icons/fa'; // Make sure to install react-icons if you haven't already
import { selectLanguage } from '../../../features/LanguageSlice';


const LeftProfileData = () => {
  let getUserInfo = useSelector(selectUserInfo).payload;
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  let hostname = window.location.hostname
    .split(".")
    .slice(1)
    .slice(-2)
    .join(".");
  const fullName = useRef();
  const email = useRef();
  const city = useRef();
  const phone = useRef();
  const postalcode = useRef();
  const [withEmail, setwithEmail] = useState(null);
  const [withSms, setwithSms] = useState(null);
  const [withWhatsapp, setwithWhatsapp] = useState(null);
  const [clinic_name, setClinicName] = useState(null);
  const [clinic_address, setClinicAddress] = useState(null);
  const [clinic_telephone, setClinicTelephone] = useState(null);
  const [clinic_id, setclinic_id] = useState(null);
  const [selectedClinic, setSelectedClinicLocal] = useState(null);
  const [clinics, setClinics] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [newClinicId, setNewClinicId] = useState(null);
  const languageValue = useSelector(selectLanguage);

  const getProData = () => {
    if (getUserInfo) {
      fullName.InnerText = `${getUserInfo?.first_name} ${getUserInfo?.last_name}`;
      email.InnerText = `${getUserInfo?.email} `;
      city.InnerText = `${getUserInfo?.city} `;
      phone.InnerText = `${getUserInfo?.phone} `;
      postalcode.InnerText = `${getUserInfo?.postal_code} `;
      setClinicName(getUserInfo?.clinic_name);
      setClinicAddress(getUserInfo?.clinic_address);
      setClinicTelephone(getUserInfo?.clinic_telephone);
      setclinic_id(getUserInfo?.clinic_id);
      setwithEmail(getUserInfo?.contact_with_email);
      setwithSms(getUserInfo?.contact_with_sms);
      setwithWhatsapp(getUserInfo?.contact_with_whatsapp);
      setSelectedClinicLocal(getUserInfo?.config_id);
    }
  };

  const setClinicData = (data) => {
    setClinicName(data.app_name);
    setClinicAddress(data.address);
    setClinicTelephone(data.phone);
  };
  
  const handleClinicChange = (event) => {
    const config_id = event.target.value;
    if (config_id !== getUserInfo.config_id) {
      setNewClinicId(config_id);
      setShowPopup(true);
    } else {
      getClinicData(config_id);
    }
  };
  
  const getClinics = () => {
    AXIOS_GET(GET_CONFIGS_URL, token)
      .then((res) => {
        setClinics(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getClinicData = (clinic_id) => {
    setLoading(true);
    AXIOS_GET(`${GET_CONFIG_URL}${clinic_id}`, token)
      .then((res) => {
        setClinicData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  
  const saveClinicChange = (config_id) => {
    setLoading(true);
    AXIOS_POST(CHANGE_CLINIC, { config_id: config_id }, token)
      .then((res) => {
        dispatch(setSelectedClinic(config_id));
        setLoading(false);
        toast.success(languageValue === "En" ? 'Clinic changed successfully.' : 'Clinic modifié avec succès.');
        dispatch(setUserConfigId(config_id));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(languageValue === "En" ? 'Failed to change clinic. Please try again.' : 'Erreur lors de la modification de la clinique. Veuillez réessayer.');
        setSelectedClinicLocal(getUserInfo.config_id);
      });
  };

  const handleConfirmClinicChange = () => {
    saveClinicChange(newClinicId);
    setShowPopup(false);
    // Immediately update the clinic data
    const newClinic = clinics.find(clinic => clinic.id === parseInt(newClinicId));
    setClinicData(newClinic);
    setSelectedClinicLocal(newClinicId);
  };

  const handleCancelClinicChange = () => {
    setSelectedClinicLocal(getUserInfo.config_id);
    setShowPopup(false);
  };

  useEffect(() => {
    if (getUserInfo) {
      getClinics();
      getProData();
      getClinicData(getUserInfo.config_id);
    }
  }, [getUserInfo]);

  return (
    <LeftContainer className="left-profil-container">
       <ToastContainer />
      <Datacardsticky>
        <DataCards>
          <Uploader
            Firstname={getUserInfo?.first_name ? getUserInfo?.first_name : "N"}
            LastName={getUserInfo?.last_name ? getUserInfo?.last_name : "A"}
          />
          <FullName ref={fullName}>
            {fullName.InnerText ? (
              fullName.InnerText
            ) : fullName.InnerText === null || fullName.InnerText === "" ? (
              "N/A"
            ) : (
              <Skeleton />
            )}
          </FullName>
          <DataContainer className="datacontainer-profil">
            <h4>
              <Translate Word="page.EmailInput" />
            </h4>
            <p
              ref={email}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {email.InnerText ? (
                email.InnerText
              ) : email.InnerText === null || email.InnerText === "" ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.CityInput" />
            </h4>
            <p
              ref={city}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {city.InnerText ? (
                city.InnerText
              ) : city.InnerText === null || city.InnerText === "" ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.PhoneInput" />
            </h4>
            <p
              ref={phone}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {phone.InnerText ? (
                phone.InnerText
              ) : phone.InnerText === null ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
          <DataContainer>
            <h4>
              <Translate Word="page.PostalCodeInput" />
            </h4>
            <p
              ref={postalcode}
              style={{ width: "70%", textAlign: "right", marginLeft: "4PX" }}
            >
              {postalcode.InnerText ? (
                postalcode.InnerText
              ) : postalcode.InnerText === null ? (
                "N/A"
              ) : (
                <Skeleton />
              )}
            </p>
          </DataContainer>
        </DataCards>
        <TitleHeader>
          <Translate Word="sidebar.attachedclinic" />{" "}
        </TitleHeader>
        <DataCards>
          {loading ? (
            <Skeleton />
          ) : (
          <DataContainer style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 style={{ marginRight: "auto" }}>
              <Translate Word="sidebar.clinicName" />
            </h4>
            <p className="clinicInfo" style={{ textAlign: "right" }}>
              {clinic_name || <Skeleton />}
            </p>
          </DataContainer>
          )}
          {loading ? (
            <Skeleton />
          ) : (
          <DataContainer style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 style={{ marginRight: "auto" }}>
              <Translate Word="sidebar.address" />
            </h4>
            <p className="clinicInfo" style={{ textAlign: "right" }}>
              {clinic_address || <Skeleton />}
            </p>
          </DataContainer>
          )}
          {loading ? (
            <Skeleton />
          ) : (
          <DataContainer style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4 style={{ marginRight: "auto" }}>
              <Translate Word="sidebar.telephone" />
            </h4>
            <p className="clinicInfo" style={{ textAlign: "right" }}>
              {clinic_telephone || <Skeleton />}
            </p>
          </DataContainer>
          )}
        </DataCards>
      </Datacardsticky>
      {showPopup && (
        <ClinicChangePopUp
          currentClinic={clinics.find(clinic => clinic.id === parseInt(getUserInfo.config_id))}
          newClinic={clinics.find(clinic => clinic.id === parseInt(newClinicId))}
          onConfirm={handleConfirmClinicChange}
          onCancel={handleCancelClinicChange}
        />
      )}
    </LeftContainer>
  );
};

export default LeftProfileData;

const LeftContainer = styled.div`
  flex: 1;
  padding: 20px;
  padding-top: 0;
  @media screen and (max-width: 500px) {
    padding: 10px;
    padding-top: 0;
    width: 100%;
  }
`;
const DataContainer = styled.div`
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-top: 1px solid #e9e9e9e8;
  @media screen and (max-width: 500px) {
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 100%;
  }
  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const FullName = styled.p`
  margin-bottom: 20px;
  color: #1c2232;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;
const DataCards = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  padding: 24px;
`;
const Datacardsticky = styled.div`
  position: sticky;
  top: 100px;
`;

const TitleHeader = styled.h1`
  color: #1c2232;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 12px;
  font-weight: 500;
`;
const Select = styled.select`
  text-align: right;
  font-size: 12px;
  padding: 10px;
  width: 50%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e9e9e9e8;
`;

