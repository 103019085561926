import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const ShowSidebarSlice = createSlice({
  name: "showSidebar",
  initialState,
  reducers: {
    sidebarReveal: (state) => {
      state.value = !state.value;
    },
  },
});

export const { sidebarReveal } = ShowSidebarSlice.actions;

export const selectSidebarStatus = (state) => state.showSidebar.value;

export default ShowSidebarSlice.reducer;
